
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { makeFilePreview } from '@/utils';

@Options({})
export default class ImgPreviewModal extends Vue {

    @Prop() file: any;
    @Prop() okCb: any;

    isAppImage: boolean = false;

    preview: any = "";
    loaded: boolean = false;
    videoUrl: string = "";
    
    created(){
        if(window.cordova)
            this.appPreview();
        else
            this.webPreview();
    }

    appPreview(){
        this.isAppImage = true;
        
        var cordovafilesrc = "";

        if(window.cordova && typeof this.file != typeof "" )
            cordovafilesrc = (<any>this.file).localURL;
        else
            cordovafilesrc = <any>this.file;

        if(typeof cordovafilesrc == typeof "" && (cordovafilesrc.indexOf(".mp4") != -1 || cordovafilesrc.indexOf(".x-m4v") != -1)){
            this.videoUrl = this.file;

            // makeFilePreviewApp(this.file)
            // .then( preview => {
            //     this.preview = preview;
            //     console.log("preview", this.preview)
            //     this.loaded = true;
            // })
        } 
        
        this.loaded = true;
    }

    webPreview(){
        makeFilePreview(this.file)
        .then( preview => {
            this.preview = preview;
            if(this.file.type.match("video"))
                this.videoUrl = URL.createObjectURL(this.file);
            this.loaded = true;
        })
    }

    ok() {
        this.okCb();
        this.$emit('close');
    }
    
}



import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class ChatHeader extends Vue {

    @Prop({
        default: ""
    }) userIdentifier: string;

    @Prop({
        default: ""
    }) userName: string;

    @Prop({
        default: false
    }) hasOptions: boolean;

    @Prop({
        default: false
    }) hasUserInfo: boolean;

}

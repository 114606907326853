
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class PhotoUploader extends Vue {

    mobile: boolean = false;
    file: any = null;

    @Prop() callback: (pic: any) => void;

    @Prop({
        default: true
    }) allowEdit: boolean;

    created(){
        // if(window.cordova)
            // this.mobile = true;
    }

    mounted(){
        if(!this.mobile)
            this.getPicWindow();
    }

    getPic(from: number) {
        if(window.cordova)
            this.getPicApp(from);
    }

    getPicApp(from: number){
        let onSuccess = (imgData: any) => {
            // StatusBar.overlaysWebView(false);
            // this.callback(imgData);
            (<any>window).resolveLocalFileSystemURL(imgData, this.callback, (err) => console.log(err));
        }
        let onError = (err: any) => {
            // StatusBar.overlaysWebView(false);
            console.log(err);
        }
        (<any>window).navigator.camera.getPicture(onSuccess, onError, {
            destinationType: 1,
            sourceType: from,
            correctOrientation: true,
            allowEdit: this.allowEdit
        })
    }

    getPicWindow(){
        (<any>this.$refs.input_file).click();
    }

    changedFile(event: any){
        this.$emit("changedFile", event.target.files[0])
    }

}


import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { ChatRoomClient, ReportClient, UserClient } from '@/services/Services';
import ReportCustomConfirm from '@/components/reportCustomConfirm.vue';
import ModalSuccessError from '@/components/modalSuccessError.vue';
import * as OM from '@/model';
import * as VM from '@/viewModel';
import { LocalizationServices } from '@/services/LocalizationServices';
import store from '@/store';
import ConfirmModal from '@/components/confirmModal.vue';

@Options({})
export default class ChatSettingsModal extends Vue {

    @Prop() userIdentifier: string;
    @Prop() chatName: string;
    @Prop() isGroupChat: boolean;
    @Prop({
        default: "/chats"
    }) buttonUrl: string;

    blockUser() {
        UserClient.blockUser(this.userIdentifier)
        .then(x => {
            for(var i = 0; i < this.$opModal.modals().length; i++) {
                this.$opModal.closeLast();
            }

            var text = LocalizationServices.getLocalizedValue("app_utentesegnalatoconsuccesso", "Utente segnalato con successo!");
            this.$opModal.show(ModalSuccessError, {
                message: text,
                buttonUrl: this.buttonUrl
            })
        })
    }

    report() {
        for(var i = 0; i < this.$opModal.modals().length; i++) {
            this.$opModal.closeLast();
        }
        
        this.$opModal.show(ReportCustomConfirm, {
            callback: (value: string) => {
                var createReport = new OM.CreateReport();
                createReport.reportType = VM.ReportType.User;
                createReport.note = value;
                createReport.itemIdentifier = this.userIdentifier;
                ReportClient.createReport(createReport)
                .then( x => {
                    for(var i = 0; i < this.$opModal.modals().length; i++) {
                        this.$opModal.closeLast();
                    }

                    var text = LocalizationServices.getLocalizedValue("app_segnalazioneavvenutaconsuccesso", "Segnalazione avvenuta con successo!");

                    this.$opModal.show(ModalSuccessError, {
                        message: text,
                        buttonUrl: this.buttonUrl
                    })
                })
            }
        })
    }

    exitFromGroup(){
        var text =  LocalizationServices.getLocalizedValue("app_sicuropdivolerusciredaquestogruppo", "Sei sicuro di voler uscire da questo gruppo?");
        this.$opModal.show(ConfirmModal, {
            text: text,
            okCb: () => {
                ChatRoomClient.removeFromChat(store.state.user.identifier, this.$route.params.identifier.toString())
                .then(x => {
                    this.$opModal.closeAll();
                    this.$router.replace("/chats");
                });
            },
            koCb: () => {
                this.$opModal.closeAll();
            }
        })
        
    }

}



import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class CustomConfirm extends Vue {

    @Prop({
        default: ""
    }) title: string;

    @Prop({
        default: ""
    }) text: string;
    
    @Prop({
        default: "Si"
    }) confirmButton: string;

    @Prop() callback: (value: boolean) => void;

    returned: boolean = false;

    returnValue(value: boolean){
        this.callback(value);
        this.returned = true;
    }

}


import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import CustomConfirm from '@/components/customConfirm.vue';
import store from '@/store';
import * as OM from '@/model';
import { LocalizationServices } from '@/services/LocalizationServices';

@Options({})
export default class ChatMessageComponent extends Vue {

    userId: string = store.state.user.identifier;

    @Prop({
        type: [Object, OM.ChatRoomMessageVM]
    }) message: OM.ChatRoomMessageVM;
    
    @Prop() namesDictionary: { [ key: string ]: string};

    @Prop() showSenderImage: boolean;
    @Prop() showSenderName: boolean;
    @Prop({
        default: false
    }) canDeleteOther: boolean;
    

    mounted(){
        let container: any = this.$refs.messageContainer;
        let imgs = container.querySelectorAll('._chatImg');
        imgs.forEach(x => {
            // x.addEventListener('click', (ev) => {
            //     if(!window.cordova){
            //         return;
            //     }
            //     var background = getComputedStyle(ev.target).backgroundImage;
            //     let url = background.substring(5, background.length -2); //toglie url('')
            //     PhotoViewer.show(url, '');
            // });
        })
    }

    openProfile(){
        if(this.message.senderIdentifier == store.state.user.identifier)
            return;

        this.$router.push('/profile/' + this.message.senderIdentifier);
    }

    getMessageClass(message: OM.ChatRoomMessageVM){
        let ris = message.senderIdentifier == this.userId ? 'right' : 'left';
        if(this.showSenderName){
            ris += ' moreMargin';
        }
        return ris;
    }

    timer: any = null;
    checkLongPress(){
        var touchduration = 400;
        this.timer = setTimeout(() => {
            this.$emit('longtouch', this.message);
        }, touchduration);
    }
    voidLongPress(){
        clearTimeout(this.timer);
    }

    deleteMessage(){
        var title = LocalizationServices.getLocalizedValue("app_attenzione", "Attenzione!");
        var text = LocalizationServices.getLocalizedValue("app_vuoidavveroeliminarequestomessaggio", "Vuoi davvero eliminare questo messaggio?");

        this.$opModal.show(CustomConfirm, {
            title: title,
            text: text,
            callback: (value: boolean) => {
                if(!value)
                    this.$opModal.closeLast();
                else 
                    this.$emit("deleteMessage", this.message);
            }

        })
    }

    get canDelete(){
        if(this.message.senderIdentifier == this.userId)
            return true;

        if(this.canDeleteOther){
            var admin = store.state.community.admins.filter( x => {
                return x.identifier == this.userId;
            });

            var moderator = store.state.community.moderators.filter( x => {
                return x.identifier == this.userId;
            });

            if(admin.length > 0 || moderator.length > 0)
                return true;
        }

        return false;
    }
}

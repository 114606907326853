import * as VM from '@/viewModel';
import { wsBaseUrl } from '@/config';

class _WsServices {

    log: string = "";
    
    _ws: WebSocket;
    private _chatRoomIdentifier: string;
    private _userIdentifier: string;
    private switchoff: boolean = false;

    Connect(chatRoomIdentifier: string, userIdentifier: string, chatRoomType: string){
        this._ws = new WebSocket(wsBaseUrl);
        this._ws.onopen = (e) => {
            let present = new VM.PresentMessage();
            present.chatRoomIdentifier = chatRoomIdentifier;
            present.userIdentifier = userIdentifier;
            present.chatRoomType = chatRoomType;
            let msg = new VM.WsClientMessage();
            msg.type = "present";
            msg.content = present;
            this._ws.send(JSON.stringify(msg));
        };
        this._ws.onmessage = (wsMsg: MessageEvent) => {
            let data = <VM.WsServerMessage<any>>JSON.parse(wsMsg.data);
            if(data.type == 'message'){
                this._messageCallback(data.content);
            } else if(data.type == 'namesDictionary'){
                this._namesDictionaryCallback(data.content);
            } else if(data.type == 'disable'){
                this._disableCallback(data.content);
            } else if(data.type == 'remove'){
                this._removeCallback(data.content);
            } else if(data.type == 'friendRequestUpgrade'){
                this._friendRequestUpgradeCallback(data.content);
            }
        }
        this._ws.onerror = (e) => {
        }
        this._ws.onclose = (e) => {
            setTimeout(() => {
                if(this.switchoff){
                    return;
                }
                this.Connect(chatRoomIdentifier, userIdentifier, chatRoomType);
            }, 1000);
        }
    }
    
    Disconnect(chatRoomIdentifier: string, userIdentifier: string, chatRoomType: string){
        this.SendDisconnectMessage(chatRoomIdentifier, userIdentifier, chatRoomType);
        this._ws.close();
        this.switchoff = true;
    }

    SendDisconnectMessage(chatRoomIdentifier: string, userIdentifier: string, chatRoomType: string){
        let disconnect = new VM.DisconnectMessage();
        disconnect.chatRoomIdentifier = chatRoomIdentifier;
        disconnect.userIdentifier = userIdentifier;
        disconnect.chatRoomType = chatRoomType;
        let msg = new VM.WsClientMessage();
        msg.type = "disconnect";
        msg.content = disconnect;
        this._ws.send(JSON.stringify(msg));
    }
    
    SendDisableMessage(chatRoomIdentifier, disable){
        let msg = new VM.WsClientMessage();
        msg.type = "disable";
        let disableMessage = new VM.DisableMessage();
        disableMessage.chatRoomIdentifier = chatRoomIdentifier;
        disableMessage.disable = disable;
        msg.content = disableMessage;
        this._ws.send(JSON.stringify(msg));
    }
    
    SendMessage(message: VM.ChatMessage){
        let msg = new VM.WsClientMessage();
        msg.type = "message";
        msg.content = message;
        this._ws.send(JSON.stringify(msg));
    }

    SendPresent(message: VM.PresentMessage){
        var msg = new VM.WsClientMessage();
        msg.type = "present";
        msg.content = message;
        this._ws.send(JSON.stringify(msg));
    }

    RemoveMessage(message: VM.RemoveMessage){
        let msg = new VM.WsClientMessage();
        msg.type = "remove";
        msg.content = message;
        this._ws.send(JSON.stringify(msg));
    }

    private _namesDictionaryCallback: (msg: VM.NamesDictionaryMessage) => void;
    OnNamesDictionary(onNamesDictionaryCallback: (msg: VM.NamesDictionaryMessage) => void){
        this._namesDictionaryCallback = onNamesDictionaryCallback;
    }
    private _messageCallback: (msg: any) => void;
    OnMessage(onMessageCallback: (msg: any) => void){
        this._messageCallback = onMessageCallback;
    }
    private _disableCallback: (disable: VM.DisableMessage) => void;
    OnDisable(onDisableCallback: (msg: any) => void){
        this._disableCallback = onDisableCallback;
    }
    private _removeCallback: (remove: VM.RemoveMessage) => void;
    OnRemove(onRemoveCallback: (msg: any) => void){
        this._removeCallback = onRemoveCallback;
    }
    private _friendRequestUpgradeCallback: (remove: VM.RemoveMessage) => void;
    OnFriendRequestUpgrade(onFriendRequestUpgradeCallback: (msg: any) => void){
        this._friendRequestUpgradeCallback = onFriendRequestUpgradeCallback;
    }
    
}

export let WsServices = new _WsServices();

import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { WsServices } from '@/services/WsServices';
import { ChatRoomClient } from '@/services/Services';
import { StorageServices } from '@/services/StorageServices';
import PhotoUploader from '@/components/photoUploader.vue';
import UoseWriter from '@/components/uoseWriter.vue';
import ChatMessage from './chatMessage.vue';
import ImgPreviewModal from './imgPreviewModal.vue';
import linkify from 'linkifyjs/html';
import store from '@/store';
import * as OM from '@/model';
import * as VM from '@/viewModel';
import { LocalizationServices } from '@/services/LocalizationServices';

@Options({
    components: {
        ChatMessage,
        UoseWriter,
        PhotoUploader
    }
})
export default class ChatComponent extends Vue {
    
    @Prop() chatRoom: OM.BaseChatRoomVm;
    @Prop() containerAdjustment: number;
    @Prop() containerAdjustmentOko: number;
    @Prop({
        default: true
    }) canWrite: boolean;

    @Prop({
        default: false
    }) canDeleteOther: boolean;

    messages: OM.ChatRoomMessageVM[] = [];
    namesDictionary: { [key: string]: string } = {};
    quotedMessage: OM.ChatRoomMessageVM = null;
    messageText: string = "";
    showNuoviMessaggi: boolean = false;
    messageContainer: HTMLElement;
    chatWriter: HTMLElement;
    isTicket: boolean = false;
    fetchingData: boolean = false;
    doneFetching: boolean;
    take: number = 50;
    writerBaseHeight: number = 0;
    isDisabled: boolean = false;
    showActionMenu: boolean = false;
    reset: number = 0;
    placeholder: string = "";

    file: File = null;

    showUploadActionBar: boolean = false;
    imgPreview: string = '';

    chatInput: any;

    created() {
        this.placeholder = LocalizationServices.getLocalizedValue("app_scriviqualcosa", "Scrivi qualcosa");
    }

    openImageUploader(){
        this.showUploadActionBar = false;

        this.$nextTick( () => {
            this.showUploadActionBar = true
        })
    }

    showSenderImage(i){
        if(i == this.messages.length - 1){
            return true;
        } else {
            return this.messages[i + 1].senderIdentifier != this.messages[i].senderIdentifier;
        }
    }

    showSenderName(i){
        return true;
    }

    changedFile(file: File){
        this.$opModal.show(ImgPreviewModal, {
            file: file,
            okCb: () => {
                ChatRoomClient.uploadChatAttachment(file)
                .then( x => {
                    store.commit('hideSpinner');
                    this.imgPreview = x;
                    this.showUploadActionBar = false;
                    this.sendImg(this.imgPreview); 
                })
                .catch( err => {
                    store.commit('hideSpinner');
                });
            }
        })
    }

    @Watch("file")
    changedFileApp(){
        if(!this.file)
            return;
        
        var cordovafilesrc = "";

        if(window.cordova && typeof this.file != typeof "" )
            cordovafilesrc = (<any>this.file).localURL;
        else
            cordovafilesrc = <any>this.file;
        
        if(window.cordova && (cordovafilesrc.indexOf(".mp4") != -1 || cordovafilesrc.indexOf(".x-m4v") != -1))
            this.readVideoOnAndroid(this.file);
        else 
            this.$opModal.show(ImgPreviewModal, {
                file: cordovafilesrc,
                okCb: () => {
                    ChatRoomClient.uploadChatAttachment(this.file)
                    .then( x => {
                        store.commit('hideSpinner');
                        this.imgPreview = x;
                        this.showUploadActionBar = false;
                        this.sendImg(this.imgPreview); 
                    })
                    .catch( err => {
                        store.commit('hideSpinner');
                    });
                }
                }, () => {{
                    this.file = null;
                }}
            )
    }

    readVideoOnAndroid(file){
        var vm = this;
        (<any>window).resolveLocalFileSystemURL(
            this.file,
            function (fileEntry) {
                // console.log(fileEntry.name)
                // console.log(fileEntry.toURL());
                // window.cordova.plugins.diagnostic.requestExternalStorageAuthorization( ()=>{
                //     vm.$opModal.show(ImgPreviewModal, {
                //         file: fileEntry.toURL(),
                //         okCb: () => {
                //             ChatRoomServices.uploadChatAttachment(this.file)
                //             .then( x => {
                //                 store.commit('hideSpinner');
                //                 this.imgPreview = x;
                //                 this.showUploadActionBar = false;
                //                 this.sendImg(this.imgPreview); 
                //             })
                //             .catch( err => {
                //                 store.commit('hideSpinner');
                //             });
                //         }
                //         }, () => {{
                //             this.file = null;
                //         }}
                //     )
                // }, (error) =>{
                //     console.log(error)
                // });

            }, 
            (err) => {
                console.log("Errore", err)
            })
    }

    messageContainerStyle: string = "";
    calcWriterHeight(){
        let ris = this.writerBaseHeight;
        let writerHeight = getComputedStyle(this.chatWriter).height;
        ris += parseFloat(writerHeight.substring(0, writerHeight.length - 2)) + 1;
        this.messageContainer.style.setProperty('--writerheight', ris + 'px');
    }
    keyboardWillShowCallback(){
        this.messageContainer.scrollTop += this.writerBaseHeight;
        this.messageContainer.style.setProperty('--containerAdjustment', this.containerAdjustmentOko + 'px');
    }
    keyboardWillHideCallback(){
        this.messageContainer.style.setProperty('--containerAdjustment', this.containerAdjustment + 'px');
    }
    mounted() {
        document.addEventListener("resume", this.resumeCallback, false);
        document.addEventListener("pause", this.pauseCallback, false);
        window.addEventListener('keyboardWillShow', this.keyboardWillShowCallback);
        window.addEventListener('keyboardWillHide', this.keyboardWillHideCallback);

        this.init();
    }

    init(){
        if(store.state.newChatMessages.indexOf(this.chatRoom.identifier) != -1){
            var foundIndex = store.state.newChatMessages.findIndex( x => x == this.$route.params.identifier.toString());
            store.state.newChatMessages.splice(foundIndex, 1);
        }
            
        this.chatInput = (<any>this.$refs.chatInput).$el.querySelector('.editor');
        this.chatWriter = <HTMLElement>this.$refs.chatWriter;
        this.messageContainer = <HTMLElement>this.$refs.chatMessageContainer;
        this.messageContainer.style.setProperty('--containerAdjustment', this.containerAdjustment + 'px');
        let writerHeight = getComputedStyle(this.messageContainer).getPropertyValue('--writerheight');
        this.writerBaseHeight = parseFloat(writerHeight.substring(0, writerHeight.length - 2));
        this.calcWriterHeight();

        Promise.all([
            ChatRoomClient.getChatMessages(this.chatRoom.identifier, this.messages.length, this.take),
            ChatRoomClient.getParticipants(this.chatRoom.identifier)
        ])
        .then( xs => {
            this.messages.push(...xs[0]);

            this.namesDictionary = {};

            xs[1].forEach( el => {
                this.namesDictionary[el.identifier] = el.name;
            })
            setTimeout(() => {
                this.scrollToBottom();
            }, 200);
            WsServices.Connect(this.chatRoom.identifier, store.state.user.identifier, this.chatRoom.type);
            WsServices.OnMessage(this.onMessage);
            WsServices.OnNamesDictionary(this.onNamesDictionary);
            WsServices.OnDisable(this.onDisable);
            WsServices.OnRemove(this.onRemove);
            WsServices.OnFriendRequestUpgrade(this.onFriendRequestUpgrade);
        })
    }

    resumeCallback(){
        this.init();
    }

    pauseCallback(){
        WsServices.Disconnect(this.chatRoom.identifier, store.state.user.identifier, this.chatRoom.type);
    }

    scrollToBottom() {
        this.messageContainer.scrollTop = this.messageContainer.scrollHeight;
    }
    
    beforeUnmount(){
        this.pauseCallback();
        document.removeEventListener("resume", this.resumeCallback, false);
        document.removeEventListener("pause", this.pauseCallback, false);

        window.removeEventListener('keyboardWillShow', this.keyboardWillShowCallback);
        window.removeEventListener('keyboardWillHide', this.keyboardWillHideCallback);
    }

    onLongtouch(val: OM.ChatRoomMessageVM){
        this.quotedMessage = {...val};
        this.quotedMessage.quotedMessage = null;
        this.$nextTick(() => {
            this.calcWriterHeight();
        });
    }

    cancelQuote(){
        this.quotedMessage = null;
        this.$nextTick(() => {
            this.calcWriterHeight();
        });
    }

    onNamesDictionary(message: VM.NamesDictionaryMessage){
        Object.keys(message.namesDictionary).forEach( key => {
            this.namesDictionary[key] = message.namesDictionary[key];
        })
    }
    
    onMessage(message: OM.ChatRoomMessageVM){
        this.messages.push(message);
        if(this.isAtBottom()){
            setTimeout(() => {
                this.goToBottom();
            }, 10);
        } else {
            //nuovi messaggi
            this.showNuoviMessaggi = true;
        }
    }

    isAtTop(){
        return this.messageContainer.scrollTop == 0;
    }
    isAtBottom(){
        let a = Math.abs(Math.floor(this.messageContainer.clientHeight - this.messageContainer.scrollHeight));
        let b = Math.floor(this.messageContainer.scrollTop + 10);
        return a <= b;
    }
    goToBottom(){
        this.messageContainer.scrollTop = this.messageContainer.scrollHeight;
    }
    
    scrollListener(){
        this.checkTopScroll();
        this.checkBottomScroll();
    }
    checkTopScroll(){
        if(!this.isAtTop())
            return;
        if(this.fetchingData || this.doneFetching)
            return;

        this.fetchingData = true;
        ChatRoomClient.getChatMessages(this.chatRoom.identifier, this.messages.length, this.take)
        .then(msgs => {
            this.fetchingData = false;
            let scrollBottom = this.messageContainer.scrollHeight - this.messageContainer.scrollTop;
            this.messages.unshift(...msgs);
            this.$nextTick(() => {
                this.messageContainer.scrollTop = this.messageContainer.scrollHeight - scrollBottom;
            });
            if(msgs.length == 0){
                this.doneFetching = true;
            }
        })
    }
    checkBottomScroll(){
        if(this.isAtBottom()){
            this.showNuoviMessaggi = false;
        }
    }

    onFriendRequestUpgrade(data: VM.UpgradeMessage){
        this.$router.replace('/chats/' + data.chatRoomIdentifier);
    }
    onDisable(data: VM.DisableMessage){
        this.isDisabled = data.disable;
    }
    onRemove(data: VM.RemoveMessage){
        let index = this.messages.findIndex(x => x.identifier == data.messageIdentifier);
        this.messages.splice(index, 1);
    }

    disableChat(){
        WsServices.SendDisableMessage(this.chatRoom.identifier, true);
        this.showActionMenu = false;
    }

    sendMessage(){
        
        if(!this.messageText.trim() || this.isDisabled) {
            return;
        }
        let parsedText = linkify(this.messageText);
        let newMessage: OM.ChatRoomMessageVM;
        newMessage = {
            identifier: "00000000-0000-0000-0000-000000000000",
            date: null,
            text: parsedText,
            senderIdentifier: store.state.user.identifier,
            quotedMessage: this.quotedMessage,
            metaData: '',
            lastReadDates: <any>{}
        }
        var wsMessage: VM.ChatMessage = {
            chatRoomIdentifier: this.chatRoom.identifier,
            message: newMessage
        }
        WsServices.SendMessage(wsMessage);
        this.messageText = "";
        this.imgPreview = "";
        this.reset++;
        this.quotedMessage = null;
        this.chatInput.focus();
        setTimeout(() => {
            this.calcWriterHeight();
        });
        this.$nextTick(() => {
            this.goToBottom();
        });
    }
    sendImg(imgUrl: string){
        let newMessage: OM.ChatRoomMessageVM;
        newMessage = {
            identifier: "00000000-0000-0000-0000-000000000000",
            date: null,
            text: imgUrl,
            senderIdentifier: store.state.user.identifier,
            quotedMessage: this.quotedMessage,
            metaData: '',
            lastReadDates: <any>{}
        }
        var chatMessage: VM.ChatMessage = {
            chatRoomIdentifier: this.chatRoom.identifier,
            message: newMessage
        }
        WsServices.SendMessage(chatMessage);
        this.messageText = "";
        this.imgPreview = "";
        this.quotedMessage = null;
        this.calcWriterHeight();
        this.$nextTick(() => {
            this.goToBottom();
        });
    }

    deleteMessage(chat: OM.ChatRoomMessageVM){
        var vm = new VM.RemoveMessage();
        vm.chatRoomIdentifier = this.chatRoom.identifier;
        vm.messageIdentifier = chat.identifier;
        vm.senderIdentifier = StorageServices.getUserIdentifier();

        WsServices.RemoveMessage(vm);
        this.$opModal.closeLast();
    }
}

import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, toDisplayString as _toDisplayString, withModifiers as _withModifiers } from "vue"
import _imports_0 from '@/assets/img/close.svg'


const _hoisted_1 = {
  key: 0,
  class: "quotedMessage"
}
const _hoisted_2 = {
  key: 2,
  class: "d-flex"
}
const _hoisted_3 = {
  key: 0,
  class: "sender"
}
const _hoisted_4 = { class: "timestamp" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_userimage = _resolveDirective("userimage")!

  return (_openBlock(), _createBlock("div", {
    class: ["chatMessage", _ctx.getMessageClass(_ctx.message)],
    onMousedown: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.checkLongPress && _ctx.checkLongPress(...args))),
    onMousemove: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.voidLongPress && _ctx.voidLongPress(...args))),
    onMouseup: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.voidLongPress && _ctx.voidLongPress(...args))),
    onTouchstart: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.checkLongPress && _ctx.checkLongPress(...args))),
    onTouchmove: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.voidLongPress && _ctx.voidLongPress(...args))),
    onTouchend: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.voidLongPress && _ctx.voidLongPress(...args)))
  }, [
    (_ctx.message.quotedMessage)
      ? (_openBlock(), _createBlock("div", _hoisted_1, [
          _createVNode("span", {
            class: "ellipsis",
            innerHTML: _ctx.message.quotedMessage.text
          }, null, 8, ["innerHTML"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.showSenderImage)
      ? _withDirectives((_openBlock(), _createBlock("div", {
          key: 1,
          class: ["chatImg", { pointer: _ctx.$store.state.user.identifier != _ctx.message.senderIdentifier }],
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.openProfile && _ctx.openProfile(...args)))
        }, null, 2)), [
          [_directive_userimage, _ctx.$store.state.getUserImage(_ctx.message.senderIdentifier)]
        ])
      : _createCommentVNode("", true),
    (_ctx.namesDictionary[_ctx.message.senderIdentifier])
      ? (_openBlock(), _createBlock("div", _hoisted_2, [
          (_ctx.showSenderName)
            ? (_openBlock(), _createBlock("p", _hoisted_3, _toDisplayString(_ctx.namesDictionary[_ctx.message.senderIdentifier]), 1))
            : _createCommentVNode("", true),
          (_ctx.canDelete)
            ? (_openBlock(), _createBlock("img", {
                key: 1,
                onClick: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.deleteMessage && _ctx.deleteMessage(...args)), ["stop","prevent"])),
                class: "close_icon",
                src: _imports_0
              }))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createVNode("span", {
      innerHTML: _ctx.message.text,
      ref: "messageContainer"
    }, null, 8, ["innerHTML"]),
    _createVNode("small", _hoisted_4, _toDisplayString(_ctx.$filters.chatDate(_ctx.message.date)), 1)
  ], 34))
}
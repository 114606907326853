import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"
import _imports_0 from '@/assets/img/close.svg'


const _hoisted_1 = { class: "d-flex align-items-center justify-content-between" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "fs-14 greyBlue" }
const _hoisted_4 = { class: "mt-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!

  return (_openBlock(), _createBlock("div", {
    class: "bottomModalContainer",
    onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('close')))
  }, [
    _createVNode("div", {
      class: "bottomModal",
      onClick: _cache[5] || (_cache[5] = _withModifiers(() => {}, ["prevent","stop"]))
    }, [
      _createVNode("div", _hoisted_1, [
        _createVNode("div", _hoisted_2, [
          _createVNode("span", _hoisted_3, _toDisplayString(_ctx.chatName), 1)
        ]),
        _createVNode("img", {
          src: _imports_0,
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
        })
      ]),
      _createVNode("div", _hoisted_4, [
        _createVNode("div", {
          class: "genericButton bgGreyBlue",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.report()))
        }, [
          _createVNode(_component_localized_text, {
            localizedKey: "app_faiunasegnalazione",
            text: "Fai una segnalazione",
            class: "fs_13 white"
          })
        ]),
        _createVNode("div", {
          class: "genericButton bgGreyBlue mt-2",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.exitFromGroup()))
        }, [
          _createVNode(_component_localized_text, {
            localizedKey: "app_escidalgruppo",
            text: "esci dal gruppo",
            class: "fs_13 white"
          })
        ]),
        (!_ctx.isGroupChat)
          ? (_openBlock(), _createBlock("div", {
              key: 0,
              class: "genericButton bgWhite mt-2",
              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.blockUser()))
            }, [
              _createVNode(_component_localized_text, {
                localizedKey: "app_bloccaUtente",
                text: "Blocca utente",
                class: "fs_13"
              })
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}